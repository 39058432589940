@use '@angular/material' as mat;
// @import '@angular/material/theming';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

$bright-navy-blue-palette: (
    50: #8096a3,
    100: #8096a3,
    200: #8096a3,
    300: #8096a3,
    400: #8096a3,
    500: #8096a3,
    600: #8096a3,
    700: #8096a3,
    800: #8096a3,
    900: #8096a3,
    A100: #8096a3,
    A200: #8096a3,
    A400: #8096a3,
    A700: #8096a3,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87),
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: rgba(black, 0.87),
        A200: rgba(black, 0.87),
        A400: white,
        A700: white,
    )
);
$gainsboro-palette: (
    50: #f9f9fa,
    100: #f3f3f4,
    200: #ebebec,
    300: #dcdcdd,
    400: #b9b9ba,
    500: #99999a,
    600: #707071,
    700: #5d5d5e,
    800: #3e3e3f,
    900: #1d1d1e,
    A100: #f5f5f5,
    A200: #eaeaeb,
    A400: #d6d6d7,
    A700: #838386,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87),
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: rgba(black, 0.87),
        A200: rgba(black, 0.87),
        A400: rgba(black, 0.87),
        A700: white,
    )
);
$crimson-palette: (
    50: #ffebef,
    100: #ffcdc5,
    200: #f0999f,
    300: #e77179,
    400: #f24e58,
    500: #f83a40,
    600: #e9313e,
    700: #d72638,
    800: #ca1e30,
    900: #bb0d24,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    )
);
$primary: mat.m2-define-palette($bright-navy-blue-palette, 700, 300, 900);
$accent: mat.m2-define-palette($gainsboro-palette, 300, 100, 500);
$warn: mat.m2-define-palette($crimson-palette, 700);
$light_theme: mat.m2-define-light-theme((
    color: (
        primary: $primary,
        accent: $accent,
        warn: $warn,
    )
));

$dark_theme: mat.m2-define-dark-theme((
    color: (
        primary: $primary,
        accent: $accent,
        warn: $warn,
    )
));

.dark-theme {
    @include mat.all-component-colors($dark_theme)
}

.light-theme {
    @include mat.all-component-themes($light_theme)
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.container {
    margin: 1%;
}

